:root {
  box-sizing: border-box;
  font-size: 1rem;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}
